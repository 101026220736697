#accounts__organisation {
  width: 50%;
  margin: 0 auto 100px auto;

  .top {
    text-align: center;
    margin-top: 76px;

    .h1 {
      color: #000;
      font-size: 28px;
    }

    .h2 {
      color: #878787;
      font-size: 15px;
      // margin-top: 5.375rem;
    }
  }

  .cards {
    margin-top: 6rem;

    .card {
      background: #ffffff;
      box-shadow: 0px 3px 6px #0000000a;
      border: 1px solid #e9e9e9;
      border-radius: 3px;
      padding: 15px;
      margin-top: 15px;

      &.edit {
        background: #fafafa;
      }

      .h1 {
        font-size: 15px;
        color: #000;
      }

      .h2,
      .label {
        font-size: 13px;
        color: #878787;
        margin: 5px 0;
      }
      .value {
        color: #094a9f;
        font-size: 15px;
      }
    }
  }

  textarea {
    height: auto !important;
  }

  .ApiKey-Content {
    margin: 20px 0px;
    #api_key {
      width: 55%;
      margin-right: 12px;
      height: 32px !important;
      background-color: rgb(245, 245, 245);
      min-width: 300px;
    }
    #api_key:hover {
      border-color: rgb(233, 233, 233) !important;
    }
    .h2 {
      padding-top: 10px;
      a:hover {
        text-decoration: underline !important;
      }
    }
  }
}
