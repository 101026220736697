.update-role-row {
  display: flex;
  flex-direction: column;
  p {
    margin : 0px;
    line-break: normal;
    white-space: initial;
  }
  .role-name {
    font-weight: bold;
  }

}
#UserRoleEditor{
  .role-form-body {
    padding: 12px 0;
  }
}
#UserRoleEditor,
#accounts__administrators .lists .list {
  .person,
  .middle {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .avatar {
    float: left;
    color: #fff;
    border-radius: 6px;
    background-color: #BABABA;
    height: 2.2rem;
    width: 2.2rem;
    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;
  }
   .role {
    color: #094A9F;
    padding-right: 3px;
    // font-size: 15px;
    cursor: pointer;
   }
  .name {
    float: left;
    margin-left: 15px;
    height: auto;
    font-weight: 600;
    color: #707070;
  }

  .email {
    color: #707070;
  }
}

#UserRoleEditor,
#accounts__administrators .lists .list.active {
  .avatar {
    background-color: #166BDB;
  }

  .name {
    color: #000;
  }
}

#accounts__administrators {
  width: 70%;
  margin: 0 auto;

  .top {
    text-align: center;
    margin-top: 76px;

    .h1 {
      color: #000;
      font-size: 28px;
    }

    .h2 {
      color: #878787;
      font-size: 15px;
      // margin-top: 5.375rem;
    }
  }

  .lists {
    margin-top: 6rem;
    margin-bottom: 6rem;

    .nav {
      .ant-btn-link {
        color: #505050 !important;
      }

      .ant-btn-primary.ant-btn-round {
        background-color: #E9F1FE !important;
        box-shadow: none !important;
        border: 0px !important;
        color: #166BDB !important;
      }
    }

    .table {
      margin-top: 4rem;

      .title {
        text-transform: uppercase;
        margin-bottom: 1rem;
        color: #5E6C84;
        font-size: 13px;
        font-weight: bold;
      }
    }

    .list {
      border: 1px solid #eee;
      margin-bottom: 0.7rem;
      border-radius: 3px;
      padding: 0.3rem;
      display: flex;
      align-content: center;
      align-items: center;

    }
  }
}

.admin__modal {
  .ant-modal-body {
    padding-top: 0px;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-modal-footer {
    border: 0px;
    padding-top: 0px;
  }

  .submitBtn {
    background-color: #044c80 !important;
    border-color: #044c80 !important;
  }
}
