@media screen and (min-width: 1200px) {
    .authWrapper__Footer {
        max-width: 70vw !important;
    }
}

@media screen and (max-width: 1100px) {
    .authWrapper__FormCard {
        width: 95vw !important;
    }
}


@media screen and (max-width: 778px) {
    #accounts__home, #accounts__organisation {
        width: 90% !important;

        .app {
            text-align: center !important;
            padding: 15px !important;

            .icon.one {
                display: block !important;
                margin-bottom: 15px !important;
            }

            .icon.two {
                display: none !important;
            }
        }
    }

    .authWrapper__FormCard {
        box-shadow: none !important;
        width: 100vw !important;
    }

    .authWrapper__FormCardRight {
        padding: 0 !important; // display: none;
    }

    .authWrapper__Footer {
        flex-direction: column;
    }

    .ant-layout-content {
        margin-left: 0px !important;
    }

    .authWrapper__FormCardRight {
        background-image: none !important;

        .formContainer {
            width: 85% !important;
            margin-left: 0% !important;
        }
    }

    #esoko__sider {
        display: none !important;
    }
}

@media screen and (max-width: 480px) {
    #esoko__sider {
        display: none !important;
    }
}