body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  background: #fff !important;
}

.ant-layout {
  background: #fff !important;
}

.ant-layout-content {
  background: #fff !important;
}

.ant-modal {
  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-title {
    font-weight: 500;
  }

  .ant-modal-bodys {
    padding: 0px;
  }

  .body {
    padding: 4% 5% 3%;
    color: #727272;
  }

  .footer {
    background-color: #f8f8f8;
    padding: 4% 5%;

    span {
      color: rgb(147, 147, 147);
      margin-right: 1rem;
      line-height: 2.5rem
    }

    img {
      height: 2rem;
      margin-right: 1rem;
    }
  }
}

.ant-menu-inline .ant-menu-item {
  font-size: 18px;
}

.rs-table-row-header .rs-table-cell {
  text-transform: uppercase;
}

.rs-table-cell-header .rs-table-cell-content {
  padding: 7px 10px;
  font-size: 14px;
  font-weight: normal;
  color: #000;
}

a,
.ant-btn-link {
  color: #044c80 !important;
}

.ant-input:hover,
.ant-select-selection:hover {
  border-color: #044c80 !important;
}

.ant-input:focus,
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #044c80 !important;
  box-shadow: 0 0 0 2px rgba(28, 78, 114, 0.2) !important;
}

.ant-menu-inline>.ant-menu-item {
  height: 50px !important;
  line-height: 50px !important;
}

.ant-dropdown-menu-item a {
  color: rgba(0, 0, 0, 0.65) !important
}

::selection {
  color: #fff !important;
  background: #c86a1d !important;
}

.alert-danger {
  background: #f8d7da;
  border-color: #f5c6cb;
  padding: 0.3rem 2rem;
  border-radius: .25rem;
  text-align: center;
  position: fixed;
  top: 17px;
  // right: 120px;
  z-index: 3002;

  .link {
    color: #721c24;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}