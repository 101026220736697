#esoko___header {
    background: #fff !important;
    position: fixed !important;
    z-index: 3000 !important;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.03), 0 1px 4px rgba(0, 0, 0, 0.03) !important;
    padding: 0 !important;
    height: 64px !important;
    top: 0 !important;

    .ant-layout-header,
    .ant-page-headers {
        box-shadow: 0 1px 6px rgba(0, 0, 0, .03), 0 1px 4px rgba(0, 0, 0, .03);
    }

    .ant-page-header {
        padding: 15px 1% !important;
        background: #fff !important;

        .ant-page-header-heading-title {
            font-size: 1.15rem;
            color: #1252A6;
            font-weight: 600;
        }

        .ant-dropdown-link {
            font-size: 18px;
            cursor: pointer;
            color: #000;
            // padding: 6px 12px;
            padding: 0px;

            img {
                margin-right: 12px;
            }

            .anticon-down {
                font-size: 12px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        .ant-page-header {
            // padding: 15px 7% !important;

            .ant-page-header-heading-title {
                float: left;
                width: 90%;
            }

            .ant-page-header-heading-extra {
                float: left;
                width: 10%;
                padding-top: 0;
            }

            .texticon {
                display: none;
            }
        }
    }
}

.ant-tooltip ,
.ant-dropdown {
    z-index: 30002 !important;
}

#esoko___drawer {
    z-index: 3000 !important;

    .MenuGridList {
        display: inline-block;
        width: 50%;
        transition: 0.2s ease-in;

        &:hover {
            cursor: pointer;
            background-color: rgba(#eee, 1);
        }
    }

    .MenuGridItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1rem;
        color: #000;

        img {
            margin-bottom: 8px;
        }

        i {
            margin-bottom: 0.5rem;
            font-size: 1.5rem;
        }
    }
}

.ant-notification,
.ant-message {
    z-index: 3003 !important;
}

.esoko__acc__switcher {
    .ant-modal-header {
        background: #F3F7FF;
    }

    .ant-modal-title {
        color: #505050;
        font-size: 13px;
    }

    .ant-modal-body {
        padding-top: 0px;
        max-height: 250px;
        overflow: scroll;
        overflow-x: hidden;
    }

    .current {
        color: #166BDB;
        font-size: 12px;
        margin-top: 25px;
        text-transform: uppercase;
    }

    .caard {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;

        &.active {
            cursor: pointer;
        }

        .logo {
            background-color: rgba(#166BDB, 0.2);
            padding: 3px;
            border-radius: 50%;
            height: 2.5rem;
            width: 2.5rem;

            img {
                width: 100%;
                border-radius: 50%;
            }
        }

        .text {
            margin-left: 12px;
            width: 83%;
            display: flex;
            align-items: center;

            .title {
                color: #000;
                font-weight: 600;
            }

            .admin {
                color: #BABABA;
            }
        }

        .clear {
            clear: both;
        }
    }
}