
#Dashboard-No-Data {
  width: 100%;
  min-width:80px;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-empty-normal {
    margin: 0;
  }
}
#DashboardTable {
  display: flex;
  flex-direction: column;
  min-height: 380px;
  #Dashboard-No-Data {
    margin-top: 50px;
  }
  .dash-title {
    padding-bottom: 0.7rem;
    display: flex;
    align-items: center;
    display: -webkit-box;
    gap : 4px;
    p {
      margin: 0;
    }
  }
  .table-header {
    border-bottom: 1px solid #F3F4F6;
    border-top: 1px solid #F3F4F6;
    padding: 0.7rem 0rem;
    font-size: 12px;
    color : gray;
    .header {
      p {
        margin: 0;
      }
    }
  }
  .table-row {
    border-bottom: 1px solid #F3F4F6;
    padding: 0.7rem 0rem;
    .data-title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;

    }
    .data-action{
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        transform: scale(1.4);
      }
    }
  }
  .explore-button {
    margin-top: 0.7rem ;
    background: transparent;
    float: right;
    color : #707070 ;
    &:hover {
      color : #166BDB;
    }
    //span {
    //  margin-right: 10px;
    //}
  }
}

#DashboardTable,
#DashboardCard {

  padding: 1.175rem;
  margin-bottom: 1.25rem;
  background-color: #fff;
  //box-shadow: 0px 3px 8px #0000000A;
  border: 1px solid #E9E9E9;
  transition: all 0.3s ease;
  .icon-title {
    &:hover {
      opacity: 0.8;
    }
  }

  //&:hover {
  //  transform: scale(1.04);
  //}
}

#DashboardCard {
  min-height: 195px;

  .stat-content {
    display: flex;
    gap: 100px;
    margin-top: 25px;
    margin-bottom: 20px;
  }

  #DashboardStat {
    //border-bottom: 1px solid #e5e5ea;

    .count-label {
      font-size: 14px;
      color: #787486;
      text-align: center;
      margin-left: 10px;
    }

    .count {
      color: #000000;
      font-size: 36px;
      margin-top: 5px;
    }
  }

}

.card-title {
  color: #23262F;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
}


.fa-arrow-up-right-from-square {
  font-size: 12px;
}

table {
  margin-top: 10px;
}


.table-dot {
  border-left: none;
  border-right: none;
}

.table-action {
  border-right: none;
  border-left: none;
  cursor: pointer;
}

.table-data {
  border-left: none;
  border-right: none;
}

.card-icon {
  margin-right: 10px;
}

.fa-arrow-up-right-from-square {
  color: #A8A7BD;
}
