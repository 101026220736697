$color-white: #fff;

.authWrapper {
    // font-family: 'Segoe UI';
    // background: rgba(235, 237, 239, 0.77);
    min-height: 100vh;
    // display: flex;
    // flex-direction: row;

    .authWrapper__Dock {
        // background: $color-primary;
        // background: url('../../assets/img/esoko_Signin_Left.svg') no-repeat;
        background-size: cover;
        width: 375px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 3.125rem;

        .authWrapper__DockBottom {
            padding-bottom: 4rem;

            p {
                color: white;
                font-size: 2rem;
            }

            span {
                color: white;
                font-size: 0.9rem;
            }
        }

        @media screen and (max-width: 880px) {
            display: none;
        }
    }

    .authWrapper__Body {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // flex-direction: column;
        // flex: 1;

        .formContainer {
            width: 40%;
            margin-left: 13%;
            // height: 100%;

            form {
                // width: 96%;
                margin: 0 auto;
            }

            .ant-form-item {
                margin-bottom: 5px;
            }

            @media screen and (max-width: 880px) {
                min-width: 60%;
            }

            @media screen and (min-width: 1200px) {

                // max-width: 450px !important;
                form {
                    // max-width: 450px !important;
                    margin: 0 auto;
                }
            }
        }



        .loginForm {
            .loginForm__linksDiv {
                p {
                    margin-top: 0.8rem;
                }
            }

            .loginForm__Btn {
                margin-top: 1.5rem;
                margin-bottom: 1.35rem;
            }
        }
    }

    .authWrapper__FormCard {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
        display: flex;
        // width: 65vw;
        // min-height: 75vh;
        min-height: 100vh;
        background-color: white;

        .authWrapper__FormCardRight {
            background-image: url('../../assets/img/signin_slash_2.svg');

            padding: 0.7rem;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            label {
                color: #505050;
            }
        }

        .authWrapper__FormCardLeft {
            background-color: #0D3B77;
            width: 45%;
            background-repeat: no-repeat;
            background-position: 100% 100%;

            // flex: 1 1;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            padding: 2rem 2rem 2rem;
            color: $color-white;

            @media screen and (max-width: 778px) {
                display: none;
            }

            .h1 {
                margin-top: 4.375rem;
                font-size: 1.75rem;
                // font-family: 'Segoe UI';
                font-weight: 300;
            }

            .h2 {
                margin-top: 2.1875rem;
                font-size: 0.8125rem;
                color: #8BA1BE;
            }

            .authWrapper__FormCardLeftTop {
                flex-grow: 0.7;

                p {
                    font-size: 1.5rem;
                }
            }

            .authWrapper__FormCardLeftMiddle {
                text-align: center;
                flex-grow: 1;
            }

            .authWrapper__FormCardLeftBottom {
                text-align: right;
                flex-grow: 0;

                p {
                    font-size: 1.95rem;
                    font-weight: bold;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }

    .authWrapper__Footer {
        width: 85vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        padding-top: 1rem;
        // margin-top: 0.5rem;
        font-size: 0.8rem;
    }

    .copyright {
        text-align: center;
        margin-top: 2rem;
        width: 98vw !important;
    }
}

#auth__header {
    .title {
        font-size: 1.125rem;
        margin-top: 0.625rem;
    }
}


#esoko__signin {

    h2,
    .formTitle {
        font-weight: normal;
        margin-bottom: 6%;
        font-weight: 700;
        font-size: 1.1875rem;
        color: rgba(0, 0, 0, 0.65);
    }

    .formLinks {
        margin-top: 0rem;
        text-align: center;

        p {
            margin-top: 2rem;
        }
    }

    .noAccountText {
        color: #707070;
        margin-right: 0.625rem;
    }

    .submitBtn {
        margin-top: 1.5rem;
        margin-bottom: 1.35rem;
        background-color: #044c80 !important;
        border-color: #044c80 !important;
    }

    .auth_form_description {
        margin-top: 0px;
        margin-bottom: 1.5em;
        color: #505050;
        background: #F8F8F8 0% 0% no-repeat padding-box;
        border-radius: 0.1875rem;
        padding: 0.625rem;
        font-size: 0.9375rem;
    }


}

.switch__lang {
    width: 80px;
    border: 0;
    font-size: 12px;

    .ant-select-selection {
        border: 0 !important;
        box-shadow: none !important;
    }
}