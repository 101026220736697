#esoko__sider {
    // min-width: 256px !important;
    background: #0D3B77 0% 0% no-repeat padding-box;
    // background: linear-gradient(to bottom right, #0D3B77 50%, #fff 50%); 
    //background: url('../../assets/img/nav_bg.svg');
    background-size: cover;
    background-position: right;

    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
        border-right: 0px;
    }

    .ant-menu {
        background: none;
        color: rgba(255, 255, 255, .8);
        margin-top: 18%;
    }

    .ant-menu-item:hover,
    .ant-menu-item a {
        color: rgba(255, 255, 255, 1) !important;
    }

    .ant-menu-inline .ant-menu-item {
        font-size: 0.9375rem;
        height: 2.8125rem !important;
        line-height: 3.125rem !important;

        .anticon {
            font-size: 1.375rem;
            margin-right: 1.375rem;
        }
    }

    .ant-menu-item span {
        margin-top: -0.125rem;
        position: absolute;
    }


    .ant-menu-item-selected {
        color: #fff;
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
        background-color: transparent;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
        {
        background-color: transparent;

        a,
        .anticon {
            color: #56AAFE !important;
        }
    }

    .ant-menu-inline .ant-menu-item,
    .ant-menu-inline .ant-menu-submenu-title {
        width: calc(100% + 0px);
    }

    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
        border: 0px;
    }

    .logo {
        padding: 1rem 1.5rem;
    }

    #balance {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 2rem 1.8rem;

        hr {
            border: 0;
            border-top: 1px solid #f7f7fa;
        }

        .amount {
            color: #fff;
            margin-bottom: .8rem;
            font-size: 0.9375rem;
        }
    }

    #balance.collapsed {
        padding: 0.6rem;

        .amount {
            font-size: 0.8125rem;
        }

        .ant-btn {
            padding: 0 0.4375rem;
        }
    }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    background-color: #fff9f0 !important;
}

.ant-divider {
    margin: 0.25rem 0px;
}