.defaultLogo {
    background-color: #EAEAEA;
    padding: 20px;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
}

.ant-input-lg::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 0.8em;
}

.ant-input-lg:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 0.8em;
}

.ant-input-lg::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 0.8em;
}

.ant-input,
.ant-select-selection {
    color: #505050 !important;
    font-weight: 500 !important;
    height: 40px !important;
    border: 1px solid #E9E9E9 !important;
}

.ant-select-selection__rendered {
    line-height: 38px !important;
}

.ant-input:hover,
.ant-input:focus {
    border-color: #306EEA !important;
    border-right-width: 1px !important;
}

.ant-input:focus {
    -webkit-box-shadow: 0 0 0 2px rgba(13, 59, 119, 0.2);
    box-shadow: 0 0 0 2px rgba(13, 59, 119, 0.2);
}

.ant-btn-primary[disabled] {
    color: #fff !important;
    opacity: .5 !important;
}