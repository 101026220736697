#accounts__settings {
  width: 50%;
  margin: 0 auto;

  .top {
    text-align: center;
    margin-top: 76px;

    .h1 {
      color: #000;
      font-size: 28px;
    }

    .h2 {
      color: #878787;
      font-size: 15px;
      // margin-top: 5.375rem;
    }
  }

  .cards {
    margin-top: 6rem;

    .card {
      background: #FFFFFF;
      box-shadow: 0px 3px 6px #0000000A;
      border: 1px solid #E9E9E9;
      border-radius: 3px;
      padding: 15px;
      margin-top: 15px;

      .h1 {
        font-size: 15px;
        color: #000;
      }

      .h2,
      .label {
        font-size: 13px;
        color: #878787;
        margin: 5px 0;
      }

      .value {
        color: #094A9F;
        font-size: 15px;
      }
    }

    .edit {
      background: #FAFAFA;
    }
  }
}